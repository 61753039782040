import { Tip, TipType } from "../Tip/Tip"
import s from "./UpdateStoks.module.css"

export const TipMetro = () => {
  return (
    <Tip
      type={TipType.WARNING}
      content={
        <span>
          <b>Внимание! </b>
          <p>
            Файл перекодировки должен быть формата <b> XLSX </b> и содержать
            следующие <b> обязательные </b> колонки:
          </p>
          <ol className={s.list}>
            <li>
              <b>article </b> - артикул товара с Метро
            </li>
            <li>
              <b>GoodCode </b> - артикул продавца с WB
            </li>
            <li>
              <b>sku </b> - баркод с WB
            </li>
            <li>
              <b>count </b> - количество товаров продаваемых в карточке
              (кратность)
            </li>
            <li>
              <b>nmId </b> - артикул ВБ
            </li>
          </ol>
        </span>
      }
    />
  )
}
