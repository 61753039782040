import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"

export type IOrder = {
  address: string | null
  deliveryType: string
  user: any
  orderUid: string
  article: string
  rid: string
  createdAt: string
  offices: string[]
  skus: string[]
  id: number
  warehouseId: number
  nmId: number
  chrtId: number
  price: number
  convertedPrice: number
  currencyCode: number
  convertedCurrencyCode: number
  cargoType: number
}

export type ISupply = {
  id: string
  done: boolean
  createdAt: string
  closedAt: string
  scanDt: string
  name: string
  cargoType: number
  orders?: IOrder[]
}

type initialStateType = {
  orders: IOrder[]
  supplies: ISupply[]
  lastDateUpdatedSupplies?: string
  storages: {
    name: string
    officeId: number
    id: number
  }[]
  ozonStorages: {
    name: string
    warehouse_id: number
  }[]
  formOrders?: {
    GoodName: string
    GoodCode: string
    Quantity: number
  }[]
}
const initialState: initialStateType = {
  orders: [],
  supplies: [],
  lastDateUpdatedSupplies: undefined,
  storages: [],
  ozonStorages: [],
}
const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setOrders: (state, action: PayloadAction<IOrder[]>) => {
      state.orders = action.payload
    },
    setOrderState(state, actions: PayloadAction<initialStateType>) {
      state.orders = actions.payload.orders
      state.supplies = actions.payload.supplies
      state.storages = actions.payload.storages
      state.ozonStorages = actions.payload.ozonStorages
      actions.payload.lastDateUpdatedSupplies &&
        (state.lastDateUpdatedSupplies = new Date(
          actions.payload.lastDateUpdatedSupplies,
        ).toLocaleString())
    },
    setSupplies(
      state,
      action: PayloadAction<{
        supplies: ISupply[]
        lastDateUpdatedSupplies?: string
      }>,
    ) {
      state.supplies = action.payload.supplies
      action.payload.lastDateUpdatedSupplies &&
        (state.lastDateUpdatedSupplies = action.payload.lastDateUpdatedSupplies)
    },
    setFormOrders(
      state,
      action: PayloadAction<initialStateType["formOrders"]>,
    ) {
      state.formOrders = action.payload
    },
  },
})

export const selectOrdersStore = (state: RootState) => state.ordersStore

export const { setOrders, setOrderState, setSupplies, setFormOrders } =
  orderSlice.actions

export default orderSlice.reducer
