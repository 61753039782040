export const metroShemaRecode = {
  article: {
    prop: "article",
    type: String,
    required: true,
  },
  GoodCode: {
    prop: "GoodCode",
    type: String,
    required: true,
  },
  sku: {
    prop: "sku",
    type: String,
    required: true,
  },
  count: {
    prop: "count",
    type: Number,
    required: true,
  },
  nmId: {
    prop: "nmId",
    type: Number,
    required: true,
  },
}
