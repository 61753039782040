import React, { useEffect, useState } from "react"
import s from "./NewOrders.module.css"
import { AppDispatch } from "../../app/store"
import {
  IOrder,
  selectOrdersStore,
  setOrderState,
  setOrders,
  setSupplies,
} from "../../app/reducers/ordersReducer"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { Modal } from "../../features/Modal/Modal"
import { CreateNewSupplies } from "./CreateNewSupplies"
import { AddToSupplies } from "./AddToSupplies"
import BaseApi from "../../api/api"
import { NotificationService } from "../../features/NotificationService/NotificationService"

const setNewOrders = () => {
  return async (dispatch: AppDispatch) => {
    BaseApi.getNewOrders()
      .then((response) => {
        response.data.success && dispatch(setOrders(response?.data?.orders))
        NotificationService.showNotification({
          message: response.data.message,
          level: (response.data.success && "success") || "error",
        })
      })
      .catch((error) => {
        dispatch(setOrders([]))
        console.error(error)
      })
  }
}

export const getOrdersState = () => {
  return async (dispatch: AppDispatch) => {
    BaseApi.getOrdersState()
      .then((response) => {
        dispatch(setOrderState(response?.data))
      })
      .catch((error) => {
        dispatch(
          setOrderState({
            orders: [],
            supplies: [],
            lastDateUpdatedSupplies: undefined,
            storages: [],
            ozonStorages: [],
          }),
        )
        console.error(error)
      })
  }
}

const updateSupplies = () => {
  return async (dispatch: AppDispatch) => {
    BaseApi.updateSupplies()
      .then((response) => {
        NotificationService.showNotification({
          message: response.data.message,
          level: (response.data.success && "success") || "error",
        })
        dispatch(
          setSupplies({
            supplies: response?.data.supplies,
            lastDateUpdatedSupplies:
              (response?.data.lastDateUpdatedSupplies &&
                new Date(
                  response?.data.lastDateUpdatedSupplies,
                ).toLocaleString()) ||
              response?.data.lastDateUpdatedSupplies,
          }),
        )
      })
      .catch((error) => {
        NotificationService.showNotification({
          message: error?.message,
          level: "error",
          errorBody: error,
        })
      })
  }
}

const addOrdersToSupplies = (supplyId: number, orderIds: number[]) => {
  return async (dispatch: AppDispatch) => {
    BaseApi.addOrdersToSupplies(supplyId, orderIds)
      .then((response) => {
        NotificationService.showNotification({
          message: response.data.message,
          level: (response.data.success && "success") || "error",
        })
        dispatch(setNewOrders())
      })
      .catch((error) => {
        NotificationService.showNotification({
          message: error?.message,
          level: "error",
          errorBody: error,
        })
      })
  }
}
const createNewSuppliesAndAddOrders = (name: string, orderIds: number[]) => {
  return async (dispatch: AppDispatch) => {
    BaseApi.createNewSuppliesAndAddOrders(name, orderIds)
      .then((response) => {
        NotificationService.showNotification({
          message: response.data.message,
          level: (response.data.success && "success") || "error",
        })
        dispatch(setNewOrders())
        dispatch(updateSupplies())
      })
      .catch((error) => {
        NotificationService.showNotification({
          message: error?.message,
          level: "error",
          errorBody: error,
        })
      })
  }
}
export const NewOrders = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getOrdersState())
  }, [])
  const [selected, setSelected] = useState<IOrder[]>([])
  const [isOpenModalCreateSupplies, setOpenModalCreateSupplies] =
    useState(false)
  const [isOpenModalAddToSupplies, setOpenModalAddToSupplies] = useState(false)
  const ordersStore = useAppSelector(selectOrdersStore)
  const { orders, storages, supplies, lastDateUpdatedSupplies } = ordersStore
  const [selectedStorageId, setSelectedStorages] = useState("")
  const [selectedSupplyId, setSelectedSupply] = useState("")

  let newSupplyName = `${
    storages.find((el) => +el.id === +selectedStorageId)?.name
  }-${new Date().toLocaleString()}`?.replace(", ", "-")
  return (
    <div>
      <div>
        <h5>Сбор заказов в поставку</h5>
      </div>
      <div className={s.orderContent}>
        <div className={s.table}>
          <div className={s.dropdown}>
            <label htmlFor="storages-select">Отфильтровать по складу</label>
            <select
              name="stock"
              id="storages-select"
              value={selectedStorageId}
              onChange={(e) => {
                setSelected([])
                setSelectedStorages(e.target.value)
              }}
            >
              <option value={""}>Выбрать склад</option>
              {storages.map((storage) => (
                <option value={storage.id} key={storage.name}>
                  {storage.name}
                </option>
              ))}
            </select>
          </div>
          <table>
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      e.target.checked ? setSelected(orders) : setSelected([])
                    }}
                    checked={selected?.length === orders.length}
                  />
                </th>
                <th>№ задания</th>
                <th>Создано</th>
                <th>Склад</th>
                <th>Артикул продавца</th>
                <th>Артикул WB</th>
                <th>Цена</th>
              </tr>
            </thead>
            <tbody>
              {orders
                .filter((ord) =>
                  selectedStorageId
                    ? +ord.warehouseId === +selectedStorageId
                    : true,
                )
                .map((el, i) => {
                  const isSelected = selected?.find(
                    (sel: any) => el.id === sel?.id,
                  )
                  return (
                    <tr
                      className={`${s.rowValue} ${
                        selected?.find((sel: any) => el.id === sel?.id)
                          ? s.active
                          : ""
                      }`}
                      key={el.article + "-" + i}
                      onClick={(e: any) => {
                        !e.target.classList.contains(s.checkbox) &&
                          setSelected([el])
                      }}
                    >
                      <td>
                        <input
                          type="checkbox"
                          className={s.checkbox}
                          onChange={(e) => {
                            e.target.checked
                              ? setSelected([...selected, el])
                              : setSelected(
                                  selected.filter(
                                    (sel: any) => sel.id !== el.id,
                                  ),
                                )
                          }}
                          checked={!!isSelected}
                        />
                      </td>
                      <td>{el.id}</td>
                      <td>{new Date(el.createdAt).toLocaleString()}</td>
                      <td>{el.warehouseId}</td>
                      <td>{el.article}</td>
                      <td>{el.nmId}</td>
                      <td>{el.convertedPrice / 100}</td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
        <div className={s.buttons}>
          <button onClick={() => dispatch(setNewOrders())}>
            Обновить список заказов
          </button>
          <button
            disabled={!selected.length || !selectedStorageId}
            onClick={() => {
              if (selected.length) {
                setOpenModalCreateSupplies(true)
              }
            }}
          >
            Создать новую поставку и добавить в нее выбранные задания
          </button>
          <button
            disabled={!selected.length}
            onClick={() => setOpenModalAddToSupplies(true)}
          >
            Добавить задания в поставку
          </button>
        </div>
      </div>
      <Modal
        isOpen={isOpenModalCreateSupplies}
        onClose={() => {
          setOpenModalCreateSupplies(false)
        }}
        header={"Создать новую поставку и добавить в нее выбранные задания"}
        onOk={() => {
          newSupplyName &&
            selectedStorageId &&
            orders.length &&
            dispatch(
              createNewSuppliesAndAddOrders(
                newSupplyName,
                orders.map((el) => el.id),
              ),
            )
        }}
        content={
          <CreateNewSupplies orders={selected} newSupplyName={newSupplyName} />
        }
        size="auto"
      />
      <Modal
        isOpen={isOpenModalAddToSupplies}
        onClose={() => {
          setOpenModalAddToSupplies(false)
        }}
        header={"Добавить выбранные задания в существующую поставку"}
        onOk={() => {
          orders.length &&
            selectedSupplyId &&
            dispatch(
              addOrdersToSupplies(
                +selectedSupplyId,
                orders.map((el) => el.id),
              ),
            )
        }}
        content={
          <AddToSupplies
            orders={selected}
            supplies={supplies}
            lastDateUpdatedSupplies={lastDateUpdatedSupplies}
            updateSupplies={() => dispatch(updateSupplies())}
            selectedSupply={selectedSupplyId}
            setSelectedSupply={setSelectedSupply}
          />
        }
        size="auto"
        isOkDisabled={!selectedSupplyId}
      />
    </div>
  )
}
