import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"

type initialStateType = {
  wildberries: {
    lastStoksUpdated: string
    lastPricesUpdated: string
    status: "Ошибка" | "Успешно" | "Ошибка в файлах" | "В процессе"
    isCurrentDataUploaded: boolean
  }
}

const initialState: initialStateType = {
  wildberries: {
    lastStoksUpdated: "",
    lastPricesUpdated: "",
    status: "Успешно",
    isCurrentDataUploaded: false,
  },
}

const updateStoksMetroSlice = createSlice({
  name: "updateStoksMetro",
  initialState,
  reducers: {
    setUpdateStoksMetroState: (
      state,
      action: PayloadAction<initialStateType>,
    ) => {
      state.wildberries = action.payload.wildberries
    },
  },
})

export const selectUpdateStoksMetroStore = (state: RootState) =>
  state.updateStoksMetro

export default updateStoksMetroSlice.reducer

export const { setUpdateStoksMetroState } = updateStoksMetroSlice.actions
