import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"

type initialStateType = {
  lastStoksUpdated: string
  lastPricesUpdated: string
  status?: "Ошибка" | "Успешно" | "Ошибка в файлах"
  isCurrentDataUploaded: boolean

  ozon?: {
    lastStoksUpdated: string
    lastPricesUpdated: string
    status?: "Ошибка" | "Успешно" | "Ошибка в файлах"
    isCurrentDataUploaded: boolean
  }

  isDataFromKatrenUploaded: boolean
}

const initialState: initialStateType = {
  lastStoksUpdated: "",
  lastPricesUpdated: "",
  isCurrentDataUploaded: false,
  isDataFromKatrenUploaded: false,
  ozon: {
    lastStoksUpdated: "",
    lastPricesUpdated: "",
    isCurrentDataUploaded: false,
  },
}

const updateStoksKatrenSlice = createSlice({
  name: "updateStoksKatren",
  initialState,
  reducers: {
    setUpdateStoksKatrenState: (
      state,
      action: PayloadAction<initialStateType>,
    ) => {
      state.lastStoksUpdated = action.payload.lastStoksUpdated
      state.lastPricesUpdated = action.payload.lastPricesUpdated
      state.status = action.payload.status
      state.isCurrentDataUploaded = action.payload.isCurrentDataUploaded
      state.isDataFromKatrenUploaded = action.payload.isDataFromKatrenUploaded
      state.ozon = action.payload.ozon
    },
  },
})

export const selectUpdateStoksKatrenStore = (state: RootState) =>
  state.updateStoksKatren

export default updateStoksKatrenSlice.reducer

export const { setUpdateStoksKatrenState } = updateStoksKatrenSlice.actions
